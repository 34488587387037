import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import "react-lazy-load-image-component/src/effects/blur.css"

// import mouseWheel from "../images/mouse-wheel.gif"

const UploadDocs = () => (
  <Layout>
    <SEO title="Upload Docs" description="Upload Docs Page" />
    {/* <div className="container-fluid hero-section">
      <div className="row">
        <div className="col-md-5 vertical-align d-none-mobile">
          <div className="brandstamp">
            <h1 className=" pb-3 pt-3">Customer Document Upload</h1>
          </div>
        </div>
        <div className="col-md-7 contact-hero-img vertical-align">
          <div className="brandstamp-mobile text-white">
            <h1>Customer Document Upload</h1>
          </div>
        </div>
      </div>
    </div> */}
    {/* <div className="col-lg-12 text-center scroll-down">
      <img className="mouse-wheel" src={mouseWheel} alt="Scroll Down" />
    </div> */}

    <div
      className="container-fluid mt-5 p-0 documents-container"
      style={{ overflow: "hidden" }}
    >
      <div className="row align-items-center">
        <div className="col-lg-12">
          <iframe
            src="https://plus.preapp1003.com/Darlene-Franklin/upload"
            name="upload_document"
            height="1400rem"
            frameBorder="0"
            width="100%"
            title="Upload Document"
            scrolling="yes"
            style={{ border: 0, marginTop: "6rem" }}
          ></iframe>
        </div>
      </div>
    </div>

    <div className="container-fluid apply-section">
      <div className="protruded-gradient"></div>
      <div className="container">
        <div className="apply-container">
          <div className="row">
            <div className="col-md-10">
              <div>
                <h2 className="mb-2 mt-3">
                  Let's get started
                  <br />
                </h2>
                <p className="pb-4">
                  Getting pre-approved can be quick and easy. Start the process
                  today!
                  <br />
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <Link to="https://plus.preapp1003.com/Darlene-Franklin" className="btn-global w-100 mb-3" type="button">
                Apply Now
              </Link>
              <Link
                to="/loan-process"
                className="btn-global-inverted w-100 text-center"
                type="button"
                style={{ padding: "10px 20px" }}
              >
                Our Process
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default UploadDocs
